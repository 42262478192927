import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/elements/Button';
import TextInput, { TextAreaInput } from 'components/elements/TextInput';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useTranslation } from 'hooks/useTypedTranslation';
import { useAuthStore } from 'modules/auth/store/authStore';

type FeatureRequestFields = { email: string; message: string };

interface FeatureRequestModalProps {
  onCancel: () => void;
  onSubmit: (data: FeatureRequestFields) => void;
}

export const FeatureRequestModal: React.FC<FeatureRequestModalProps> = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation('common');

  const currentUser = useAuthStore(state => state.user);

  const schema = yup.object().shape({
    email: yup.string().email(t('A valid email address is required')).required(t('A valid email address is required')),
    message: yup.string().required(t('A valid feature request is required'))
  });

  const {
    formState: { errors },
    handleSubmit: handleSubmitWrapper,
    register,
    setFocus
  } = useForm<FeatureRequestFields>({
    resolver: yupResolver(schema),
    defaultValues: { email: currentUser?.email || '' },
    shouldUnregister: true
  });

  useEffect(() => {
    setTimeout(() => {
      setFocus('email');
    }, 250);
  }, [setFocus]);

  const handleSubmit = (data: { email: string; message: string }) => {
    onSubmit(data);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="fixed inset-0 z-[100] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
          <div className="bg-white p-5 pl-2">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Feature Request')}</h3>
              </div>
            </div>
          </div>

          <form className="m-0" onSubmit={handleSubmitWrapper(handleSubmit)}>
            <div className="w-full p-6 pt-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('Email')}
              </label>
              <TextInput
                className="speechify-ignore-keybindings"
                error={errors.email}
                // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                name="email"
                placeholder={t('Email')}
                {...register('email')}
                type="email"
              />
            </div>

            <div className="w-full p-6 pt-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                {t('Feature')}
              </label>
              <div className="mt-1">
                <TextAreaInput className="speechify-ignore-keybindings" error={errors.message} rows={5} {...register('message')} />
              </div>
            </div>

            <div className="mt-3 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <Button title={t('Submit')} type="submit" />
              <Button className="mr-2" color="default" onClick={handleCancel} title={t('Cancel')} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeatureRequestModal;
